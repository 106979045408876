var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.itemStorages)?_c('div',{staticClass:"itemStorages"},[_c('Breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('DataTable',{ref:"itemStoragesPaginatedTable",staticClass:"p-datatable-sm p-datatable-striped p-datatable-gridlines",attrs:{"value":_vm.itemStorages,"lazy":true,"loading":_vm.isLoading,"totalRecords":_vm.getItemStorageCount,"paginator":true,"rows":_vm.tableState.pagination.pageSize,"first":_vm.pageOffset,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","currentPageReportTemplate":_vm.$t('show') +
        ' {first} ' +
        _vm.$t('to') +
        ' {last} ' +
        _vm.$t('of') +
        ' {totalRecords} ' +
        _vm.$t('records'),"rowsPerPageOptions":[10, 15, 20, 25, 30, 40, 50, 100],"filters":_vm.tableState.filters,"stateStorage":"local","stateKey":_vm.tableState.filterName,"filterDisplay":"row","rowHover":true,"sortField":_vm.tableState.sortField,"sortOrder":_vm.tableState.sortOrder,"responsiveLayout":"scroll"},on:{"update:rows":function($event){return _vm.$set(_vm.tableState.pagination, "pageSize", $event)},"update:filters":function($event){return _vm.$set(_vm.tableState, "filters", $event)},"page":function($event){return _vm.onPage($event)},"sort":function($event){return _vm.onSort($event)},"filter":function($event){return _vm.onFilter($event)},"state-restore":function($event){return _vm.onStateRestore($event)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-header-left"},[_c('h4',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$t('itemStorages')))])]),_c('div',{staticClass:"table-header-right"},[_c('button',{staticClass:"btn btn-sm btn-inverse",on:{"click":function($event){$event.preventDefault();return _vm.onRemoveFilters()}}},[_c('i',{staticClass:"fa fa-remove mr-2"}),_vm._v(" "+_vm._s(_vm.$t('remove_filters'))+" ")]),_c('router-link',{staticClass:"btn btn-sm btn-inverse",attrs:{"to":{ name: 'ItemStorageCreatePage' }}},[_c('i',{staticClass:"fa fa-plus-circle mr-2"}),_vm._v(" "+_vm._s(_vm.$t('itemStorage_create'))+" ")])],1)])]},proxy:true},{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('no_data_found'))+" ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('loading'))+" ")]},proxy:true}],null,false,1835841297)},[_c('Column',{attrs:{"field":"number","header":"#","sortable":"","showFilterMenu":false,"headerStyle":{ width: '60px' },"bodyStyle":{ 'text-align': 'center' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(slotProps.data.number)?_c('router-link',{attrs:{"to":{
            name: 'ItemStorageEditPage',
            params: { itemStorageNumber: slotProps.data.number },
          }}},[_vm._v(_vm._s(slotProps.data.number.toString().padStart(4, '0')))]):_vm._e()]}},{key:"filter",fn:function({filterModel,filterCallback}){return [_c('InputText',{attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}],null,false,2426286609)}),_c('Column',{attrs:{"field":"name","header":_vm.$t('name'),"sortable":""},scopedSlots:_vm._u([{key:"filter",fn:function({filterModel,filterCallback}){return [_c('InputText',{attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}],null,false,4046805386)}),_c('Column',{attrs:{"field":"itemStorageType","header":_vm.$t('itemStorageType'),"sortable":"","headerStyle":{ width: '120px' },"bodyStyle":{ 'text-align': 'center' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('b-badge',[_vm._v(" "+_vm._s(_vm.$t(slotProps.data.itemStorageType))+" ")])]}},{key:"filter",fn:function({filterModel, filterCallback}){return [_c('Dropdown',{staticClass:"p-column-filter",attrs:{"options":_vm.getEnumValues('ItemStorageType'),"showClear":true},on:{"input":function($event){return filterCallback()}},scopedSlots:_vm._u([{key:"option",fn:function(slotProps){return [_c('b-badge',[_vm._v(" "+_vm._s(_vm.$t(slotProps.option))+" ")])]}}],null,true),model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}],null,false,3723753698)})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }