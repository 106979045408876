<template>
  <div class="itemStorages" v-if="itemStorages">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <DataTable
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
      ref="itemStoragesPaginatedTable"
      :value="itemStorages"
      :lazy="true"
      :loading="isLoading"
      :totalRecords="getItemStorageCount"
      :paginator="true"
      :rows.sync="tableState.pagination.pageSize"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 15, 20, 25, 30, 40, 50, 100]"
      :filters.sync="tableState.filters"
      stateStorage="local"
      :stateKey="tableState.filterName"
      filterDisplay="row"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      :rowHover="true"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <h4 class="page-title">{{ $t('itemStorages') }}</h4>
          </div>
          <div class="table-header-right">
            <button class="btn btn-sm btn-inverse" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>

            <router-link class="btn btn-sm btn-inverse" :to="{ name: 'ItemStorageCreatePage' }">
              <i class="fa fa-plus-circle mr-2"></i>
              {{ $t('itemStorage_create') }}
            </router-link>
          </div>
        </div>
      </template>

      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column
        field="number"
        header="#"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data.number"
            :to="{
              name: 'ItemStorageEditPage',
              params: { itemStorageNumber: slotProps.data.number },
            }"
            >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
          >
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="name" :header="$t('name')" sortable>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="itemStorageType"
        :header="$t('itemStorageType')"
        sortable
        :headerStyle="{ width: '120px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <b-badge>
            {{ $t(slotProps.data.itemStorageType) }}
          </b-badge>
        </template>

        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="getEnumValues('ItemStorageType')"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge>
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import { getActiveStateColor } from '@/helpers/colors';
import Dropdown from 'primevue/dropdown';

export default {
  components: {
    DataTable,
    Breadcrumbs,
    Column,
    InputText,
    Dropdown,
  },
  data() {
    return {
      loading: false,
      defaultFilters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        itemStorageType: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      tableState: {
        pagination: { skip: 0, pageSize: 10 },
        sorting: { sortField: 'number', sortOrder: 1 },
        filters: this.defaultFilters,
        filterName: 'item_storage_table_filters',
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'isLoading',
      'getItemStorages',
      'getItemStorageCount',
      'getClients',
      'getEnumValues',
    ]),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('itemStorages') }];
    },
    itemStorages() {
      return this.getItemStorages;
    },
    pageOffset() {
      return this.tableState.pagination.skip * this.tableState.pagination.pageSize;
    },
    defaultSorting() {
      return { sortField: 'number', sortOrder: 1 };
    },
    defaultPagination() {
      return { skip: 0, pageSize: 10 };
    },
  },
  methods: {
    ...mapActions([
      'fetchItemStoragesPaginated',
      'fetchEmployers',
      'fetchEnumValues',
      'fetchUserByNumber',
      'fetchUserAttachmentUrl',
    ]),
    ...mapActions('auth', ['refreshUser']),

    /**
     * Load remote table data
     */
    async loadTableData() {
      await this.fetchItemStoragesPaginated({
        pagination: this.tableState.pagination,
        sorting: this.tableState.sorting,
        filters: { ...this.tableState.filters },
      });
    },

    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination = { skip: event.page, pageSize: event.rows };
      this.loadTableData();
    },
    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sorting = { sortField: event.sortField, sortOrder: event.sortOrder };
      this.loadTableData();
    },
    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadTableData();
    },
    async onRemoveFilters(event) {
      this.tableState.sorting = this.defaultSorting;
      this.tableState.pagination = this.defaultPagination;
      this.tableState.filters = this.defaultFilters;

      localStorage.removeItem(this.tableState.filterName);
      await this.loadTableData();
    },
    async onStateRestore(event) {
      // console.log('onStateRestore()', event, this.tableState);
      if (this.tableState) {
        this.tableState.sorting.sortField = event.sortField
          ? event.sortField
          : this.defaultSorting.sortField;
        this.tableState.sorting.sortOrder = event.sortOrder
          ? event.sortOrder
          : this.defaultSorting.sortOrder;
        this.tableState.pagination.skip = 0;
        this.tableState.pagination.pageSize =
          event.rows > 0 ? event.rows : this.defaultPagination.pageSize;
        this.tableState.filters = this.tableState.filters
          ? this.tableState.filters
          : this.defaultFilters;
      }
    },

    getActiveStateColor,
  },

  async created() {},

  async mounted() {
    await this.fetchEnumValues('ItemStorageType');

    this.loading = false;
    await this.loadTableData();
  },
};
</script>

<style lang="scss">
.p-column-filter-menu-button {
  display: none;
}
.p-column-filter-clear-button {
  display: none;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}

.p-calendar .p-datepicker {
  min-width: 100%;
  width: 29em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}
</style>
